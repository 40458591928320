import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { HeroSimpleCentered } from '../../components/Global/Hero'
import { YellowButton, BlackButton } from '../../components/Global/Button'
import SEO from '../../components/Global/SEO'
import { GiantsCausewayTour, GameOfThronesTour, CarrickARedeTour, BelfastCityTour, BelfastPoliticalTour, BespokeTour } from '../../components/Tours/Preview'

const ToursPage = ({ location }) => {
  const { seoImg } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "giants-causeway.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
    }
  `)
  
  return (
    <Layout>

      <SEO
        title="Private Chauffeur Tours of Ireland | Giant's Causeway, Guinness Storehouse"
        description="If you are planning a sightseeing tour on your visit to Ireland and want to experience the Emerald Isle in the most sophisticated way – Chauffeur Me have a range of tours to meet your needs."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <HeroSimpleCentered>
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Private
          <br className="xl:hidden" />
          <span className="text-yellow-300"> Chauffeured Tours</span>
        </h2>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          If you are planning a sightseeing tour on your visit to Ireland and want to experience the Emerald Isle in the most sophisticated way – Chauffeur Me have a range of tours to meet your needs.
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <YellowButton to="/book-now" className="flex items-center justify-center rounded-sm shadow sm:mr-2 mb-4 sm:mb-0">Book Now</YellowButton>
          <BlackButton to="/services" className="flex items-center justify-center rounded-sm shadow sm:ml-2">Our Services</BlackButton>
        </div>
      </HeroSimpleCentered>

      <div className="mt-12 pb-24">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mt-0">
            <ul className="md:grid md:grid-cols-2 lg:grid-cols-3 md:col-gap-8 md:row-gap-10">
              <GiantsCausewayTour />
              <GameOfThronesTour />
              <BelfastCityTour />
              <CarrickARedeTour />
              <BelfastPoliticalTour />
              <BespokeTour />
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ToursPage